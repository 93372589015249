import { render, staticRenderFns } from "./Industrializacao.vue?vue&type=template&id=261231b5&scoped=true&lang=pug&"
import script from "./Industrializacao.vue?vue&type=script&lang=js&"
export * from "./Industrializacao.vue?vue&type=script&lang=js&"
import style0 from "./Industrializacao.styl?vue&type=style&index=0&id=261231b5&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "261231b5",
  null
  
)

export default component.exports